import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFound from "./pages/NotFound";
import Home, {HOME_URL} from "./pages/Home";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path={HOME_URL} Component={Home}/>
            <Route path="/*" Component={NotFound}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
