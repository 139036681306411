

export function CheckIcon({className, ...props}: React.ComponentPropsWithoutRef<'svg'>) {
    return (
        <svg
            aria-hidden="true"
            className={'h-6 w-6 flex-none fill-current stroke-current ' + className}
            {...props}
        >
            <path
                d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                strokeWidth={0}
            />
            <circle
                cx={12}
                cy={12}
                r={8.25}
                fill="none"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}


export function Plan({
                  name,
                  price,
                  description,
                  href = undefined,
                  features,
                  enabled = true,
                  featured = false,
              }: {
    name: string
    price: string
    description: string
    href?: string
    features: Array<string>
    enabled?: boolean,
    featured?: boolean
}) {
    return (
        <section
            className={'flex flex-col rounded-3xl px-6 m-6 sm:px-8 py-8 ' + (featured ? 'order-first bg-blue-100 lg:order-none' : 'bg-gray-100')}
        >
            <h3 className="mt-5 font-display text-lg text-slate-800">{name}</h3>
            <p className={'mt-2 text-base ' + (featured ? 'text-slate-700' : 'text-slate-600')}>
                {description}
            </p>
            <p className="order-first font-display text-5xl font-light tracking-tight text-slate-600">
                {price}
            </p>
            <ul
                role="list"
                className={'order-last mt-10 flex flex-col gap-y-3 text-sm' + (featured ? 'text-white' : 'text-slate-200')}
            >
                {features.map((feature) => (
                    <li key={feature} className="flex">
                        <CheckIcon className={featured ? 'text-slate-700' : 'text-slate-600'} />
                        <span className="ml-4">{feature}</span>
                    </li>
                ))}
            </ul>
            {href !== undefined ?
                <a
                    href={href}
                    className="mt-8 group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600"
                    aria-label={`Get started with the ${name} plan for ${price}`}
                >
                    Get started
                </a> : <a
                    className="mt-8 group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gray-600 text-white active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600"
                    aria-label={`Get started with the ${name} plan for ${price}`}
                >
                    Coming soon
                </a>}
        </section>
    )
}