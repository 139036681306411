import {Link} from "react-router-dom";
import {ReactNode} from "react";
import {Helmet} from "react-helmet";

let appTitle = "ReplyBot.io";
let defaultDescription = "Automate repetitive tasks straight out of your inbox.\n" +
    "Simply connect your email inbox, define your actions and the workflows to automate them and replybot handles the rest!";

export default function Page({children, path, title, description} : {
    children: ReactNode,
    path:string,
    title:string,
    description?:string,
}) {
    let calcTitle = title === undefined ? appTitle : title + " | " + appTitle;
    return <>
            <Helmet>
                <title>{calcTitle}</title>
                <meta name="title" content={calcTitle}/>
                <meta property="og:title" content={title === undefined ? appTitle : title}/>
                <meta name="description" property="og:description" content={description === undefined? defaultDescription : description}/>
                <meta name="canonical" property="og:url" content={process.env.REACT_APP_HOSTNAME + path}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content={process.env.REACT_APP_HOSTNAME + "/img/Social.webp"} />
            </Helmet>
            <div className="h-full">
                <div className="w-[100%] p-4 bg-[#F0F0F0] align-middle">
                    <div className="max-w-[1280px] mx-auto">
                        <div className="inline">
                            <div className="inline p-2 font-extrabold text-3xl">
                                <Link to={"/"}>
                                    <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span><span className="text-blue-700">.io</span><img alt="replybot logo" className="inline h-12 ml-1 -mt-1 aspect-square" src={process.env.PUBLIC_URL + '/img/logo192.webp'} />
                                </Link>
                            </div>
                        </div>
                        <div className="inline float-right h-full">
                            <div className="p-2">
                                <a href={process.env.REACT_APP_APPURL}>
                                    <span className="rounded px-2 py-1 mt-10 hover:underline cursor-pointer">Login</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="m-4 mt-0 max-w-[1024px] mx-auto block">
                    {children}
                </div>
            </div>
        </>
}
