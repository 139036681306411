import Page from "../ui/Page";
import {Link} from "react-router-dom";
import {Plan} from "../ui/Pricing";

export let HOME_URL = "/"



export default function Home() {
    return <Page title="Automate repetitive tasks straight out of your inbox." path={HOME_URL}>
        <div className="text-center mb-16 md:mb-96">
            <div className="align-middle my-16 mx-auto inline-block">
                <img src={process.env.PUBLIC_URL + "/img/HomeRobot_256.webp"} alt="reply bot robot" className="h-64 inline aspect-square"/>
                <div className="inline p-2 font-extrabold margin-y-auto text-5xl md:text-7xl">
                    <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span><span className="text-blue-700">.io</span><img alt="replybot logo" className="inline h-14 md:h-16 ml-2 -mt-1 aspect-square" src={process.env.PUBLIC_URL + '/img/logo192.webp'} />
                </div>
            </div>
            <h2 className="text-[#004080] text-2xl md:text-4xl">Automate repetitive tasks straight <u className="text-[#D32F2F]">out of your inbox.</u></h2>
        </div>
        <div className="mt-4 max-w-[800px] mx-auto">
            <div className="m-6">
                <div className="overflow-hidden bg-blue-50 p-8 md:py-4 rounded-2xl md:rounded-b-none">
                    <img src={process.env.PUBLIC_URL + "/img/Running_256.webp"} alt="reply bot robot" className="h-56 mx-auto md:mr-4 md:float-left aspect-square"/>
                    <div className="md:pt-10">
                        <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span> automates repetitive tasks directly from your email, saving your team hours of manual work.
                        This streamlined process not only speeds up response times but also frees your team to focus on more strategic, high-value work.
                        By reducing the manual workload, <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span> boosts productivity and ensures faster resolutions to email-based requests
                    </div>
                </div>
                <div className="overflow-hidden bg-blue-50 p-8 md:py-4 mt-4 md:mt-0 rounded-2xl md:rounded-none">
                    <img src={process.env.PUBLIC_URL + "/img/EasySteps_256.webp"} alt="reply bot robot" className="h-56 mx-auto md:float-right aspect-square"/>
                    <div className="md:pt-16">
                        Get setup in 3 easy steps: <br/>
                        <span className="font-bold">1)</span> Connect your email the same way as email clients. <br/>
                        <span className="font-bold">2)</span> Define actions and the workflows that handle them. <br/>
                        <span className="font-bold">3)</span> Save time, effort and frustration. <br/>
                    </div>
                </div>
                <div className="overflow-hidden bg-blue-50 p-8 md:py-4 mt-4 md:mt-0 rounded-2xl md:rounded-t-none">
                    <img src={process.env.PUBLIC_URL + "/img/Security_256.webp"} alt="reply bot robot" className="h-56 mx-auto md:mr-4 md:float-left aspect-square"/>
                    <div className="md:pt-16">
                        We know how important security and privacy is. <br/>
                        We do not save email body or subjects to our servers, only the metadata we need.
                        Credentials are secured by using customer specific encryption keys and only decrypted when connecting.
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2">
                <Plan
                    featured
                    name="Pro User"
                    price="$27/month"
                    description="All of the features for one user."
                    href={process.env.REACT_APP_APPURL + "/login"}
                    features={[
                        '15 day free trial',
                        'Unlimited number of actions',
                        'One user',
                        'One mailbox',
                    ]}
                />
                <Plan
                    name="Small Business"
                    price="Coming soon!"
                    description="Email managements for teams."
                    features={[
                        '15 day free trial',
                        'Unlimited number of actions',
                        'Multiple users per workspace',
                        'Multiple mailboxes',
                    ]}
                />
            </div>
        </div>
        <div className="text-center p-10 text-gray-500">
            contact@replybot.io<span className="mx-4">◇</span>© 2024 Jotting, Inc
        </div>
    </Page>
}